import { ThunkAction } from 'redux-thunk'

import { Address, TokenId } from '../model'
import { LimitOrder } from '../model/limitOrderModel'
import { State } from '../reducers'
import {
  FailFetchLimitOrdersAction,
  InitFetchLimitOrdersAction,
  LimitOrdersAction,
  LimitOrdersActionTypes,
  SetLimitOrdersAction,
} from '../reducers/limitOrdersReducer'
import { fetchLimitOrders } from '../services/limitOrdersService'

export const initFetchLimitOrders = (): InitFetchLimitOrdersAction => ({
  type: LimitOrdersActionTypes.initFetchLimitOrders,
})

export const failFetchLimitOrders = (error: string): FailFetchLimitOrdersAction => ({
  type: LimitOrdersActionTypes.failFetchLimitOrders,
  payload: error,
})

export const setLimitOrders = (orders: LimitOrder[], error?: string): SetLimitOrdersAction => ({
  type: LimitOrdersActionTypes.setLimitOrders,
  payload: { limitOrders: orders, error: error },
})

export const getLimitOrdersData =
  (
    account: Address,
    currentTokenId: TokenId
  ): ThunkAction<Promise<void>, State, unknown, LimitOrdersAction> =>
  async (dispatch): Promise<void> => {
    if (account && currentTokenId) {
      dispatch(initFetchLimitOrders())
      fetchLimitOrders(account, currentTokenId)
        .then(({ orders, error }) => {
          if (!error) {
            dispatch(setLimitOrders(orders))
          }

          if (error) {
            dispatch(setLimitOrders([], error))
          }
        })
        .catch((error) => {
          console.error('Error while receiving limit orders', error)
          dispatch(failFetchLimitOrders(error))
        })
    }
  }
